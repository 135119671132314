<template>
<!-- 查看发票 -->
    <div class="viewInvoice">
        <van-nav-bar
            title="查看发票"
            left-arrow
            @click-left="onClickLeft"
            fixed
            :z-index="100"
        />
        <section>
            <div class="viewInvoice-text">
                ---发票申请中---
            </div>
        </section>
        
    </div>
</template>

<script setup>
export default{
    data(){
        return{
           
            imgUrl:""
        }
    },
    created(){
   
    },
    methods:{
        // 返回
        onClickLeft() {
            this.$router.back(0);
        },
        
    },
}
</script>

<style lang="less" scoped>
@import "../../../assets/styles/element.less";

.viewInvoice{
    section {
        position: relative;
        top: 46px;
        height: calc(100vh - 46px);
        display: flex;
        justify-content: center;
        
        .viewInvoice-text{
            margin-top: .6rem /* 30/50 */;
            font-size: .32rem /* 16/50 */;
            color: rgb(99, 97, 97);
        }
    }
    
}

</style>